import {Component, Provide, Vue} from 'vue-property-decorator';
import common from '../Common/Index.vue'
import {AccountRegisterReq, VerificationCodeReq} from "@/interface/res/login";
import {AccountRegisterApi, SmsRegisterApi} from "@/network/modules/login";
import {pass, phone} from "@/utils/regExp";

@Component({
  components: {
    common
  },
})
export default class Register extends Vue {
  @Provide() form:AccountRegisterReq = {
    nickName:'',
    password:'',
    passwordAgain:'',
    phone:'',
    phoneCode:'',
    platformType:1
  };
  @Provide() loading: boolean = false
  @Provide() codeLoading: boolean = false
  @Provide() phoneMsg: string = ''
  @Provide() phoneCodeMsg: string = ''
  @Provide() nickNameMsg: string = ''
  @Provide() passwordMsg: string = ''
  @Provide() passwordAgainMsg: string = ''
  @Provide() codeNum: number = 60
  @Provide() msgType: string = 'err'

  @Provide() routeLink:string = ''

  created(){
    const _this:any = this;
    if(!_this.$base.isNull(_this.$route.query.r)){
      _this.routeLink = _this.$decryptBy(_this.$route.query.r)
    }
  }
  /**
   * @Author HS
   * @Date 2021/6/30 5:59 下午
   * @Description: 注册账号
   * @Params: null
   * @Return: null
  */
  async registerFn(){
    const _this:any = this;

    if(!phone.test(_this.form.phone)){
      _this.phoneMsg = "请输入正确的手机号码"
      return
    }
    _this.phoneMsg = ""

    if(_this.$base.isNull(_this.form.phoneCode)){
      _this.phoneCodeMsg = "请输入验证码"
      return
    }
    _this.phoneCodeMsg = ""

    if(_this.$base.isNull(_this.form.nickName)){
      _this.nickNameMsg = "请输入昵称"
      return
    }
    _this.nickNameMsg = ""

    if(_this.$base.isNull(_this.form.password)){
      _this.passwordMsg = "密码不能为空"
      return
    }
    _this.passwordMsg = ""

    if(!pass.test(_this.form.password)){
      _this.passwordMsg = "仅限8~16位，数字、字母、字符至少包含两种"
      return
    }
    _this.passwordMsg = ""

    if(_this.form.password != _this.form.passwordAgain){
      _this.msgType = 'err'
      _this.passwordAgainMsg = "前后密码不一致"
      return
    }
    _this.passwordAgainMsg = ""

    _this.loading = true
    let params:AccountRegisterReq = {..._this.form}
    params.password = _this.$adminEncryptBy(params.password)
    params.passwordAgain = _this.$adminEncryptBy(params.passwordAgain)
    const res = await AccountRegisterApi(params)
    let a = setTimeout(()=>{_this.loading = false;clearTimeout(a)},500)
    const { code , msg } = res
    if(code != 200 ){
      _this.msgType = 'err'
      _this.passwordAgainMsg = msg
      return
    }
    _this.msgType = 'success'
    _this.passwordAgainMsg = "注册成功"
    let b = setTimeout(()=>{
      _this.passwordAgainMsg = ""
      _this.$base.goRouter(_this.$LoginConfig.login.path)
      clearTimeout(b)
    },1500)
  }

  /**
   * @Author HS
   * @Date 2021/6/29 2:19 下午
   * @Description: 页面跳转
   * @Params: { string ： path - true } [path：路径]
   * @Return: null
   */
  goRouter(path:string){
    const _this:any = this;
    let code = _this.$route.query
    for (let codeKey in code) {
      code[codeKey] = _this.$decryptBy(code[codeKey])
    }
    let codeStr = _this.$base.serializeStr(code)
    const link = _this.$base.routeLinkSplice(_this.$LoginConfig[path].path,codeStr)
    _this.$base.goRouter(link)
  }

  /**
   * @Author HS
   * @Date 2021/6/29 3:35 下午
   * @Description: 获取验证码
   * @Params: { 类型 ： 字段 - 是否必须 } [参数：]
   * @Return:
  */
  async getVerificationCodeFn(){
    const _this:any = this;
    if(_this.codeLoading)return

    if(!phone.test(_this.form.phone)){
      _this.phoneMsg = "请输入正确的手机号码"
      return
    }
    _this.phoneMsg = ""

    _this.codeLoading = true
    let params:VerificationCodeReq = {
      phone:_this.form.phone
    }
    const res = await SmsRegisterApi(params)
    let a = setTimeout(()=>{_this.codeLoading = false;clearTimeout(a)},500)
    const { code , msg } = res
    if(code != 200 ){
      _this.phoneMsg = msg
      return
    }

    _this.phoneMsg = ""
    _this.codeNum = 59
    _this.countdownFn()
  }

  /**
   * @Author HS
   * @Date 2021/6/29 3:17 下午
   * @Description: 倒计时
   * @Params: null
   * @Return: null
  */
  countdownFn(){
    const _this:any = this
    let time = setTimeout(()=>{
      _this.codeNum--

      if(_this.codeNum > 0){
        clearTimeout(time)
        _this.countdownFn()
        return
      }
      _this.codeNum = 60
      clearTimeout(time)
    }, 1000);
  }

}
